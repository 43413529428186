globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"65a0be2fa7561aecd8a420cff856c165b42fccf4"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'
import { version } from './package.json'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN,
  release: `v${version}`,
  environment: ENVIRONMENT,
  maxValueLength: 2000,
  enabled: ENVIRONMENT === 'prod',
})

Sentry.setTag('side', 'server')
